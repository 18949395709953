'use client'
import { useRouter } from 'next/navigation'
import type React from 'react'

const AppPage: React.FC = () => {
	const router = useRouter()
	router.push('/manager/home')
	return <div>Redirecting...</div>
}

export default AppPage
